import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
:root {
  --black: #000000;
  --white: #FFFFFF;
  --primary: #4B89FF;
  --primary-20: rgba(75, 137, 255, 0.2);
  --secondary: #F1F6FD;
  --highlight: #FECA01;
  --error: #F9474E;
  --favorite: rgba(254, 202, 1, 1);
  --success: #53B530;
  --text-primary: #181818;
  --text-primary-20: rgba(24, 24, 24, 0.2);
  --icons: #4C4C4C;
  --mui-icons: rgba(0, 0, 0, 0.54);
  --dark-theme-background: linear-gradient(
      112.87deg,
      rgba(75, 137, 255, 0.25) 0%,
      rgba(0, 147, 255, 0.25) 11.11%,
      rgba(0, 156, 255, 0.25) 22.22%,
      rgba(123, 97, 255, 0.25) 33.32%,
      rgba(0, 163, 249, 0.25) 33.33%,
      rgba(117, 100, 255, 0.25) 33.34%,
      rgba(0, 169, 241, 0.25) 44.44%,
      rgba(204, 65, 158, 0.25) 55.56%,
      rgba(0, 178, 219, 0.25) 66.67%,
      rgba(242, 200, 0, 0.25) 77.78%,
      rgba(0, 185, 195, 0.25) 88.89%,
      rgba(2, 187, 184, 0.25) 100%
    ),
    #01166a;
  --sidebar: linear-gradient(90deg, rgba(75, 137, 255, 0.28) 0%, rgba(255, 255, 255, 0.28) 100%);
  --inactive-light: #E4E4E4;
  --inactive-dark: #B1B1B1;
  --notification-daily: #55BFE9;
  --notification-monthly: #53B8B7;
  --background: #173391;
  --background-card: rgba(255, 255, 255, 0.85);
  --background-unconfirmed: rgba(255, 255, 255, 0.5);
  --background-discarded: rgba(255, 255, 255, 0.2);
  --background-confirmed: rgba(255,255,255, 1);
  --mini-dropdshadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  --elevation-meetingcard-header-bottom:  0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --elevation-meetingcard-header-top:  0px -4px 4px 0px rgba(0, 0, 0, 0.25);
  --elevation-basic: 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 2px -1px rgba(0, 0, 0, 0.2);
  --elevation-highlight-darkmode: 0px 2px 4px -2px rgba(0, 0, 0, 0.4), 0px 2px 2px rgba(0, 0, 0, 0.4), 0px 6px 2px rgba(0, 0, 0, 0.24);
  --elevation-darkmode: 0px 3px 1px rgba(0, 0, 0, 0.24), 0px 1px 1px rgba(0, 0, 0, 0.28), 0px 1px 2px -1px rgba(0, 0, 0, 0.4);

}
    body {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-primary);

    }

    html {
      font-family: FiraSans ;
      background: var(--background);
    }

    span {
      font-weight: 400;
      font-size: .85rem;
      line-height: 1.5 ;
    }

    h2 {
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.3;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.6;
    }

`

export default GlobalStyles
