import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: ['FiraSans', '-apple-system', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: 1.3,
      color: 'var(--secondary)',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.6,
    },
    caption: {
      fontSize: '0.88rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.88rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#4C4C4C',
    },
  },
  palette: {
    primary: {
      main: '#173391',
    },
    secondary: {
      main: '#F1F6FD',
    },
    error: {
      main: '#F9474E',
    },
    common: {
      black: '#181818',
    },
    text: {
      primary: '#181818',
    },
    info: {
      main: '#FFFFFF',
    },
  },
})

export default theme
