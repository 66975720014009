import React from 'react'
import type { GatsbyBrowser } from 'gatsby'

import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ThemeProvider } from '@mui/material/styles'
import fetch from 'isomorphic-fetch'

import theme from './src/gatsby-theme-material-ui/theme'
import Layout from './src/components/Layout'

// https://www.gatsbyjs.com/docs/how-to/local-development/environment-variables/#accessing-environment-variables-in-the-browser
const httpLink = new HttpLink({
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT_HTTP,
  credentials: 'same-origin',
  headers: {
    Authorization: `Basic ${process.env.BCS_TEST_USER_BASIC_AUTH}`,
  },
  fetch,
})
const isBrowser = typeof window !== `undefined`

const authLink = setContext((_, { headers }) => {
  const token = (isBrowser && localStorage.getItem('token')) || ''
  return {
    credentials: 'same-origin',
    headers: {
      ...headers,
      Authorization: `Basic ${token}`,
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

import './src/styles/typography.css'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }, props) => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Layout {...props}>{element}</Layout>
      </ThemeProvider>
    </ApolloProvider>
  )
}
