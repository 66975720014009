import React from 'react'
import GlobalStyles from '../styles/GlobalStyles'
// import Typography from '../styles/Typography'

interface LayoutProps {
  children: React.ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children }) => (
  <>
    <GlobalStyles />
    {/* ToDo: resolve flash bug when card is animated */}
    {/* <Typography /> */}
    {children}
  </>
)

export default Layout
